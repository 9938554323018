<template>
  <div>
    <v-container fluid>
      <v-row align="center" class="list mx-auto">
        <v-col cols="6" md="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items/Página"
            centered
            @change="handlePageSizeChange"
          />
        </v-col>
        <v-col cols="12" md="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            total-visible="10"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-card class="mx-auto list" width="95%" tile>
          <v-row>
            <v-card-title>Cotizaciones registradas</v-card-title>
            <v-spacer />
            <v-card-subtitle
              v-text="'Mostrando ítems ' + currentItems + ' de ' + totalItems"
            />
          </v-row>
          <v-data-table
            dense
            :headers="headers"
            :items="cotizaciones"
            disable-pagination
            :hide-default-footer="true"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-icon
                  :elevation="hover ? 16 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mr-2"
                  @click="newinfsema(item.SPC)"
                >
                  mdi-information-variant
                </v-icon>
              </v-hover>
            </template>
          </v-data-table>
          <v-card-actions v-if="oc_s.length > 0">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            />
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ordenesdecompra",
  components: {},
  data: () => ({
    totalPages: 0,
    page: 1,
    pageSize: 100,
    pageSizes: [100, 250, 500],
    currentItems: "",
    totalItems: 0,
    oc_s: [],
    headers: [
      { text: "Fecha", value: "FECHA", align: "start", sortable: false },
      {
        text: "Solicitante",
        value: "SOLICITANTE",
        width: "120px",
        sortable: false,
      },
      { text: "Empresa", value: "EMPRESA", align: "start", sortable: false },
      {
        text: "Concepto",
        value: "CONCEPTO",
        align: "start",
        sortable: false,
      },
      { text: "$ Costo", value: "COSTO", align: "center", sortable: false },
      {
        text: "V°B° MTTO",
        value: "VB_MTTO",
        align: "left",
        width: "120px",
        sortable: false,
      },
      {
        text: "V°B° Gestion",
        value: "VB_GESTION",
        align: "left",
        width: "120px",
        sortable: false,
      },
      {
        text: "V°B° gerencia",
        value: "VB_GERENCIA",
        align: "left",
        width: "120px",
        sortable: false,
      },
      { text: "Ver", value: "actions", align: "center", sortable: false },
    ],
    cotizaciones: [
      {
        FECHA: "2021-11-26",
        SOLICITANTE: "solicitante1",
        EMPRESA: "metalurgia",
        CONCEPTO: "fabricar proteccion",
        COSTO: "$ 524.251",
        VB_MTTO: false,
        VB_GESTION: false,
        VB_GERENCIA: false,
      },
      {
        FECHA: "2021-11-26",
        SOLICITANTE: "solicitante1",
        EMPRESA: "tornero",
        CONCEPTO: "rectificar eje",
        COSTO: "$ 56.251",
        VB_MTTO: true,
        VB_GESTION: false,
        VB_GERENCIA: false,
      },
      {
        FECHA: "2021-11-26",
        SOLICITANTE: "solicitante2",
        EMPRESA: "servicio integ",
        CONCEPTO: "reparar motor",
        COSTO: "$ 150.251",
        VB_MTTO: true,
        VB_GESTION: false,
        VB_GERENCIA: false,
      },
    ],
  }),
  methods: {},
  mounted() {},
};
</script>
